@import '../../../../styles/basics';

.bb-other-ways-to-donate {
  &:global(.bb) {
    @include bb-standalone-spacing();
  }

  &__wrapper {
    display: grid;
    grid-gap: 16px;
    // One or two columns depending on available space.
    grid-template-columns: repeat(auto-fit, minmax(min(100%, max(152px, calc(50% - 16px))), 1fr));
  }

  &__link {
    position: relative;
    font-size: 18px;
    line-height: $line-height-normal;
    font-weight: $font-weight-bold;
    color: $black;
    padding: 14px 16px;
    background-color: $biscuit-100;
    border-radius: 8px;

    &:hover,
    &:focus-visible {
      text-decoration: none;
      text-decoration-skip-ink: none;
      color: $black;

      .bb-other-ways-to-donate__label {
        text-decoration-thickness: 3px;
      }
    }
  }

  &__icon {
    margin-right: 12px;
    margin-bottom: 12px;
    object-fit: scale-down;
    height: 40px;
    max-width: 50px;
    align-self: center;
    justify-self: center;
  }

  &__label {
    max-width: 170px;
    width: 100%;
    position: relative;
    align-self: center;
    text-underline-offset: 2px;
    padding-right: 0;
    text-decoration: underline;
  }

  &__badge {
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 14px;
    max-width: 50px;
  }

  @include media-breakpoint-up(md) {
    &__wrapper {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @include media-breakpoint-up(lg) {
    &__badge {
      max-width: none;
    }
  }

  @include media-breakpoint-up(xl) {
    &__wrapper {
      margin-top: 44px;
    }

    &__link {
      display: flex;
      padding: 20px 16px;
      gap: 16px;
      max-width: none;
      font-size: 22px;
    }

    &--with-label {
      .bb-other-ways-to-donate__link {
        padding-top: 32px;
        padding-bottom: 32px;
      }
    }

    &__icon {
      height: auto;
      max-width: none;
      margin: 0;
      width: 48px;
    }

    &__label {
      max-width: none;
    }

    &__badge {
      right: unset;
      top: 12px;
      left: 16px;
    }
  }
}

